/* NavigationBar.css */
body{
  background-color: antiquewhite;

}
.navbars {
  overflow: hidden;
  width: 100%;
  background-color: #c3fb73;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  
  

}

.logo {
  color: color(srgb red green blue);
  font-size: 30px;

  
}

.links {
  display: flex;
  margin-left: 20px;
}

.links a {
  font-size: 15px;
  color:color(srgb red green blue);
  text-decoration: none;
  padding: 10px ;
  
}
