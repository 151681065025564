.columns {
  display: grid;
  grid-gap: 20px;
  margin-top: 10px;
  height: max-content;
  margin-bottom: 5px;
}

.column {
  padding: 0 15px;
}

.card {
  background-color: beige;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-items {
  color: #333;
  text-decoration: none;
}

.icon {
  margin-right: 15px;
}

ul {
  list-style-type: none;
}
.home-description{
  width: 80%;
  margin: auto;
  background-color: aquamarine;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 30px;
  
}
.slide-container{
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .columns {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
  }
}
